import * as THREE from 'three';
import './css/style.css';
import gsap from 'gsap';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import gltfPath from './models/general.glb';
import exrPath from './textures/studiolights.exr';
import dotsVs from './shaders/dots.vs.glsl';
import dotsFs from './shaders/dots.fs.glsl';

let suzanne;
let suzanneMesh, wingsMesh, feathers;
let controls;
let obj;
let exrCubeRenderTarget;
let exrBackground;
let frontLight;
let composer;

let clock = new THREE.Clock();
const speed = 2; //units a second
let delta = 0;

const uniforms = {
    u_resolution: { value: { x: null, y: null } },
    u_time: { value: 0.0 },
    u_mouse: { value: { x: null, y: null } },
};

const scene = new THREE.Scene();
const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
const raycaster = new THREE.Raycaster();
const renderer = new THREE.WebGLRenderer();
renderer.setSize( window.innerWidth, window.innerHeight );

document.body.appendChild( renderer.domElement );


function init() {
    THREE.DefaultLoadingManager.onLoad = function () {
        pmremGenerator.dispose();
        const loadingScreen = document.getElementById( 'loading-screen' );
		loadingScreen.classList.add( 'fade-out' );
		
		// optional: remove loader from DOM via event listener
		loadingScreen.addEventListener( 'transitionend', function(event) {
            console.log("done");
            event.target.remove();
        } );
    };

    

    new EXRLoader().load( exrPath, function ( texture ) {
        exrCubeRenderTarget = pmremGenerator.fromEquirectangular( texture );
        exrBackground = exrCubeRenderTarget.texture;
        scene.environment = exrBackground;
        
        texture.dispose();

        render();

    } );

    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.outputEncoding = THREE.sRGBEncoding;

    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();

    controls = new OrbitControls( camera, renderer.domElement );
    //controls.enablePan = false;

    new GLTFLoader().load(gltfPath, function ( gltf ) {
        suzanne = gltf.scene;
        scene.add(suzanne);
        suzanneMesh = suzanne.children[1];
        wingsMesh = suzanne.children[3];
        //feathers = wingsMesh.children[0];
        console.log(wingsMesh);

        //feathers.material.opacity = 0.5;
        //feathers.material.transparent = true; 
    }, undefined, function ( error ) {

        console.error( error );

    } );

    /*const light = new THREE.AmbientLight( 0xffffff ); // soft white light
    scene.add(light);
    frontLight = new THREE.DirectionalLight( 0xf7e895 );
    scene.add(frontLight);
    frontLight.position.set(5,0,15); //x,z,y
    
    const backLight = new THREE.DirectionalLight( 0x68bcec );
    scene.add(backLight);
    backLight.position.set(-5,5,-10); */
    camera.position.set(0,8,20);
    //controls.update() must be called after any manual changes to the camera's transform
    controls.update();
}
const mouse = {
    x: undefined,
    y: undefined
}



addEventListener('mousemove', (event) => {
    var rect = renderer.domElement.getBoundingClientRect();
    mouse.x = ( ( event.clientX - rect.left ) / rect.width ) * 2 - 1;
    mouse.y = - ( ( event.clientY - rect.top ) / rect.height ) * 2 + 1;
    uniforms.u_mouse.value.x = mouse.x;
    uniforms.u_mouse.value.y = mouse.y;
})

addEventListener( 'mousedown', (event) => {
    if (suzanne) {
        raycaster.setFromCamera(mouse, camera);
        const hit = raycaster.intersectObject(suzanneMesh);
	    if (hit.length > 0) {
            //console.log( hit[0] );

            //var material = new THREE.MeshNormalMaterial();
            obj = hit[0].object;
            /* frontLight.target = obj;
            scene.add(frontLight.target); */
            const distanceFromCamera = 10;  // 3 units
            const target = new THREE.Vector3(0, 0, -distanceFromCamera);
            target.applyMatrix4(camera.matrixWorld);
            const distance = obj.position.distanceTo(target);
            if (distance > 0) {
                gsap.to(camera.rotation, {
                    x: 0,
                    y: 0,
                    z: 0,
                    duration: .5 
                }); 
                gsap.to(camera.position, {
                    x: 0,
                    y: 6,
                    z: obj.position.z + 20,
                });    
                controls.update();
                
            }
            //obj.material = material;
        }
    }
});
addEventListener( 'resize', (event) => {
    renderer.setSize( window.innerWidth, window.innerHeight );
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    if (uniforms.u_resolution !== undefined){
        uniforms.u_resolution.value.x = window.innerWidth;
        uniforms.u_resolution.value.y = window.innerHeight;
    }
});

/* const sphere = new THREE.Mesh (
    new THREE.SphereGeometry(1,32,32),
    new THREE.ShaderMaterial({
        vertexShader: dotsVs,
        fragmentShader: dotsFs,
        uniforms 
    })
)

scene.add(sphere);
sphere.position.x = 3;

const sphereLight = new THREE.PointLight( 0xffffff, 1 );
scene.add(sphereLight);
sphereLight.position.set(sphere.position.x, sphere.position.y, sphere.position.z);

composer = new EffectComposer( renderer );

const renderPass = new RenderPass( scene, camera );
composer.addPass( renderPass );

const bloomPass = new UnrealBloomPass( new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5, 0.4, 0.85 );
bloomPass.radius = 5;
composer.addPass( bloomPass ); */

function animate() {
	requestAnimationFrame( animate );
    /*  hover effect
     if(suzanne) {
        raycaster.setFromCamera(mouse, camera);
        const hit = raycaster.intersectObject(suzanneMesh);
	    if (hit.length > 0) {
            //console.log(hit[0]);
        }
    }  */
    //delta = clock.getDelta();
      // update time uniform
    uniforms.u_time.value = clock.getElapsedTime();
    controls.update();
    render();
}

function render () {
    //let background = exrBackground;
    //scene.background = background;
    scene.background = new THREE.Color( 0x4eace2 );
    //composer.render( scene, camera );
    renderer.render( scene, camera );
}

function onTransitionEnd( event ) {

	event.target.remove();
	
}

init();
animate();



